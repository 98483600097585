
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import CONFIG from "app/config";
import { query, mutation } from "../graphql/friends_activity_graphql";
import uploadSvc from "app/services/upload";
import randomString from "app/services/randomString";
import { v4 as uuidv4 } from 'uuid';
import { resolve } from "promise";
import { showMessage } from "app/store/fuse/messageSlice";


export const getFriendsActivityList = createAsyncThunk(
    "missionApp/mission/getFriendsActivityList",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getFriendsActivityList,
        });

        return response.data.data.getFriendsActivityList;
    }
);

export const getAllFriendsActivity = createAsyncThunk(
    "missionApp/mission/getAllFriendsActivity",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getAllFriendsActivity,
        });

        return response.data.data.getAllFriendsActivity;
    }
);


export const getFriendsActivityListPopular = createAsyncThunk(
    "missionApp/mission/getFriendsActivityListPopular",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getFriendsActivityListPopular,
        });

        return response.data.data.getFriendsActivityListPopular;
    }
);




export const createIpAddress = createAsyncThunk(
    "missionApp/mission/createIpAddress",
    async (data) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.createIpAddress,
            variables: {
                data: JSON.stringify(data),
            },
        });

        return response;
    }
);



const saveImages = (itemData) => {

    itemData.fileid = uuidv4();

    return new Promise(async (resolve, reject) => {

        if (itemData.images.length) {

            var newImages = [];

            itemData.images.map(async (image, index) => {

                if (image.status === "new") {
                    newImages.push({
                        index: index,
                        image: image
                    })
                } else {
                    if (itemData.featuredImageId == image.fileId) {
                        itemData.featuredImagePath = image.path;
                    }
                }

            });

            if (newImages.length) {

                newImages.map(async (image, index) => {

                    await uploadSvc({
                        file: image.image.file,
                        destination_file_name: "missions/" + randomString() + "-" + (image.image.file ? image.image.file.name : image.image.fileName)
                    }).then(async (result) => {

                        if (result.status === 200 && result.data.status === "success") {

                            var uploadData = result.data.uploadData
                            uploadData['module'] = 'mission';
                            uploadData['file_id'] = image.image.id;
                            uploadData['fileid'] = itemData.fileid;

                            const response = await axios.post(CONFIG.API + "/api/", {
                                query: mutation.createPublicUpload,
                                variables: {
                                    data: JSON.stringify(uploadData),
                                },
                            });

                            if (response.data.data.createPublicUpload.status == "success") {
                                var upload = response.data.data.createPublicUpload.upload;

                                if (itemData.featuredImageId == image.image.id) {
                                    itemData.featuredImagePath = upload.path;
                                }
                                itemData.images[image.index].upload_id = upload.id;
                                itemData.images[image.index].file_name = upload.fileName;
                                itemData.images[image.index].file_id = upload.fileId;
                                itemData.images[image.index].file_type = upload.fileType;
                                itemData.images[image.index].path = upload.path;
                                itemData.images[image.index].module = upload.module;
                            }
                        }

                        if (newImages.length == (index + 1)) {
                            resolve(itemData);
                        }

                    });

                });

            } else {
                resolve(itemData)
            }

        } else {
            resolve(itemData);

        }

    });
}

export const getFriendsActivity = createAsyncThunk(
    "friendsActivityApp/getFriendsActivity",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getFriendsActivity,
            variables: {
                agentId: params.agentId,
                category: params.category,
                start: params.start,
                end: params.end,
            }
        });

        if (response.data.data.getFriendsActivity) {
            const data = response.data.data.getFriendsActivity;

            var tempItems = [];

            if (data) {
                data.map((itemD) => {
                    itemD.joiners = itemD.otherJoiners ? itemD.otherJoiners.map(item => item.flName).join(', ') : "";
                    tempItems.push(itemD);
                })
            }

            return data === undefined ? null : tempItems;
        } else {
            return null;
        }
    }
);

export const updateLikes = createAsyncThunk(
    "friendsActivity/updateLikes",
    async (params, { dispatch, getState }) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.updateLikes,
            variables: { data: JSON.stringify(params) },
            publicUrl: "agents"
        });

        if (response.data.data.updateLikes) {
        }
    }
);

export const updateDislikes = createAsyncThunk(
    "friendsActivity/updateDislikes",
    async (params, { dispatch, getState }) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.updateDislikes,
            variables: { data: JSON.stringify(params) },
            publicUrl: "agents"
        });

        if (response.data.data.updateDislikes) {
        }
    }
);

const FriendsActivitySlice = createSlice({
    name: "friendsActivityApp",
    initialState: null,
    reducers: null,
    extraReducers: {
        [getFriendsActivityListPopular.fulfilled]: (state, action) => action.payload,
        [getFriendsActivityList.fulfilled]: (state, action) => action.payload,
        [createIpAddress.fulfilled]: (state, action) => action.payload,
        [updateLikes.fulfilled]: (state, action) => action.payload,
        [updateDislikes.fulfilled]: (state, action) => action.payload,
    },
});

export default FriendsActivitySlice.reducer;
