import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { IconButton } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { updateDislikes, updateLikes } from '../store/FriendsActivitySlice';
import { useNavigate } from 'react-router-dom';

const LikeButton = ({ missionId, userId, initialAction }) => {

    console.log(initialAction);
    const [isLiked, setIsLiked] = useState(initialAction === 'like');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        setIsLiked(initialAction === 'like');
    }, [initialAction]);

    const handleLikeToggle = useCallback(() => {
        setIsLiked((prevState) => {
            const newState = !prevState;
            const action = newState ? 'like' : 'unlike';

            if (action === 'like') {
                dispatch(updateLikes({ userId, module: "missions", missionId, action }))
                    .then((result) => {
                        console.log(result.payload);
                        navigate('/public/friends-activity');
                    })
                    .catch((error) => {
                        console.error('Error updating like status:', error);
                        setIsLiked(!newState);
                    });
            } else {
                dispatch(updateDislikes({ userId, module: "missions", missionId, action }))
                    .then((result) => {
                        console.log(result.payload);
                        navigate('/public/friends-activity');
                    })
                    .catch((error) => {
                        console.error('Error updating dislike status:', error);
                        setIsLiked(!newState);
                    });
            }

            return newState;
        });
    }, [dispatch, missionId, userId, navigate]);

    return (
        <IconButton
            size="small"
            onClick={handleLikeToggle}
            className="-mt-2"
        >
            {isLiked ? (
                <FavoriteIcon fontSize="inherit" sx={{ color: 'red' }} />
            ) : (
                <FavoriteBorderIcon fontSize="inherit" sx={{ color: 'red' }} />
            )}
        </IconButton>
    );
};

export default LikeButton;
