
import axios from 'axios';
import React, { useState, useRef, useEffect } from "react";

import {
    Box,
    Modal,
    Stack,
} from "@mui/material";
import { savePublicUpload, getAllPublicUploads } from "app/services/store/PublicUploadsSlice";
import { useDispatch, useSelector } from "react-redux";
import FuseLoading from "@fuse/core/FuseLoading";
import { styled } from "@mui/material/styles";
import ReactPlayer from "react-player";
import ReactImageVideoLightbox from "react-image-video-lightbox";
import AttachmentSkeletonLoader from './comments/components/AttachmentSkeletonLoader';

const DisabledPlayer = styled('div')(({ theme }) => ({
    position: "relative",

    "& .disable": {
        position: "relative",
        height: 80,
        width: 80,
    },
    "& .react-player": {
        position: "absolute",
        top: 0,
        left: 0,
    },


}));
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'rgba(0, 0, 0, 0.5)',
    boxShadow: 0,
    p: 4,
    '& div:first-of-type': {
        backgroundColor: 'transparent !important',
        '& img': {
            zIndex: -1,
            maxWidth: "800px !important",
        },
        '& div': {
            bgcolor: 'rgba(0, 0, 0, 0.15)',
            borderRadius: "999px",
            margin: "6px"
        },
        '& div:first-of-type': {
            bgcolor: 'rgba(0, 0, 0, 0.15)  !important',
            borderRadius: "0px !important",
            margin: "6px"
        }
    }
};
const AttachmentContainer = (props) => {
    const dispatch = useDispatch();
    const [attachmentData, setAttachmentData] = useState([]);
    const [loading, setLoading] = useState(true);
    const { id } = props;

    const [open, setopen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const openlightbox = (index) => {
        setCurrentIndex(index);
        setopen(true);
    };
    const [openModal, setClose] = React.useState(true);
    const handleClose = () => setClose(true);

    const extractEmbedUrl = (link) => {
        const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([\w-]+)/i;
        const match = link.match(regex);

        if (match) {
            const videoId = match[1];
            return `https://www.youtube.com/embed/${videoId}`;
        } else {
            console('Invalid YouTube link');
        }
    };

    useEffect(() => {
        dispatch(getAllPublicUploads({ id: id })).then((action) => {
            const data = action.payload;
            const finalData = data.map((item, index) => ({
                ...item,
                switchType: item.fileType.split('/')[0],
                type: item.fileType.split('/')[0] == 'image' ? 'photo' : (item.fileType.split('/')[0] == 'youtube' ? 'video' : item.fileType.split('/')[0]),
                url: item.fileType.split('/')[0] == 'youtube' ? extractEmbedUrl(item.srcUrl) : item.srcUrl,
                index: index
            }));
            setAttachmentData(finalData)
            setLoading(false)
        })
    }, [dispatch]);

    return (
        <div>
            {
                loading ? (<AttachmentSkeletonLoader />) :

                    <Stack direction={'row'} gap={.5} className={attachmentData.length > 0 ? 'mt-10' : ''} flexWrap={'wrap'}>
                        {
                            attachmentData.length > 0 &&
                            attachmentData.map((item) => (
                                <Stack direction={'row'} gap={1.5} flexWrap={'wrap'}>

                                    <div
                                        style={{ width: 80, height: 80 }}
                                        className="overflow-hidden bg-white rounded-lg border-1 border-pi-light-grey"
                                    >
                                        {
                                            (() => {
                                                switch (item.switchType) {
                                                    case "youtube":
                                                        return <div className="image-container">
                                                            <DisabledPlayer onClick={() => openlightbox(item.index)}>
                                                                <div className=" z-10 disable"></div>
                                                                <ReactPlayer
                                                                    light={true}
                                                                    playing={false}
                                                                    className="react-player rounded-lg overflow-hidden "
                                                                    url={item.srcUrl}
                                                                    height={80}
                                                                    width={80}
                                                                />
                                                            </DisabledPlayer>
                                                        </div>;
                                                    case "video":
                                                        return <div className="image-container">
                                                            <video autoPlay={false} className="blog" onClick={() => openlightbox(item.index)}>
                                                                <source
                                                                    type={item.fileType}
                                                                    src={item.srcUrl}
                                                                />
                                                            </video>
                                                        </div>;
                                                    default:
                                                        return <div className="image-container2">
                                                            <img
                                                                onClick={() => openlightbox(item.index)}
                                                                className="max-w-none w-full h-full object-cover"
                                                                src={item.srcUrl}
                                                            />
                                                        </div>;
                                                }
                                            })()
                                        }
                                    </div>
                                </Stack>
                            ))
                        }

                        {open && (
                            <Modal
                                disableEnforceFocus
                                disableAutoFocus
                                keepMounted
                                onClose={handleClose}
                                open={openModal}
                                aria-labelledby="keep-mounted-modal-title"
                                aria-describedby="keep-mounted-modal-description"
                            >
                                <Box
                                    sx={style} className="w-full h-full">
                                    <ReactImageVideoLightbox
                                        data={attachmentData}
                                        startIndex={currentIndex}
                                        showResourceCount={true}
                                        onCloseCallback={() => setopen(false)}
                                        onNavigationCallback={(currentIndex) =>
                                            console.log(`Current index: ${currentIndex}`)
                                        }
                                    />
                                </Box>
                            </Modal>
                        )}

                    </Stack>

            }
        </div>
    );
};

export default AttachmentContainer;