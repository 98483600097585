const mutation = {

  createIpAddress: `
        mutation createIpAddress($data: JSONString) {
           createIpAddress (data: $data) {
                status,
                res,
                message
            }
        }
    `,

  updateLikes: `
        mutation updateLikes($data: JSONString) {
           updateLikes(data: $data) {
                status,
                res,
                message
            }
        }
    `,

  updateDislikes: `
        mutation updateDislikes($data: JSONString) {
           updateDislikes(data: $data) {
                status,
                res,
                message
            }
        }
    `,

};

const query = {
  getFriendsActivityList: `
    query getFriendsActivityList {
        getFriendsActivityList {
          id,
          title,
          description,
          lat,
          lng,
          address,
          agent,
          kingpin,
          dateCreated,
          comments{
            id,
            comment,
            replies{
              reply,
              repliesImages{
                preSignedUrl
              }
            }
            commentsImages{
              preSignedUrl
            }
          },
            markers{
              id,
              description,
              lat,
              lng,
              pin,
              markersImages{
                preSignedUrl
              }
            },
           agents{
            id
            email
            firstName
            lastName
            middleName
            }
          missionsFile{
            preSignedUrl
          }
        }
      }
    `,

  getFriendsActivityListPopular: `
    query getFriendsActivityListPopular {
        getFriendsActivityListPopular {
          id,
          title,
          description,
          lat,
          lng,
          address,
          agent,
          kingpin,
          dateCreated,
          comments{
            id,
            comment,
            replies{
              reply,
              repliesImages{
                preSignedUrl
              }
            }
            commentsImages{
              preSignedUrl
            }
          },
            markers{
              id,
              description,
              lat,
              lng,
              pin,
              markersImages{
                preSignedUrl
              }
            },
           agents{
            id
            email
            firstName
            lastName
            middleName
            }
          missionsFile{
            preSignedUrl
          }
        }
      }
    `,
  getFriendsActivity: `
      query getFriendsActivity($agentId: String, $category: String, $start: Int, $end: Int){
        getFriendsActivity(agentId: $agentId, category: $category, start: $start, end: $end) {
          missionId
          kingPin
          mission{
            id
            agentId
            title
            description
            filesId
            dateCreated
            pinned
            views
            commentsCount
            agent{
              id
              userId
              profilePicPath
              name
              flName
              username
            }
          }
          pinMission{
            id
            agentId
            title
            description
            filesId
            likes
            action
            dateCreated
            agent{
              id
              userId
              profilePicPath
              name
              flName
              username
            }
          }
          commentCount
          otherJoiners{
            userId
            id
            flName
          }
        }
    }
    `,
};

export { mutation, query };
