import React from 'react';
import { Card, CardContent, CardHeader, Divider, Skeleton, Stack } from "@mui/material";
import { Box } from '@mui/system';

const AgentSkeletonLoader = () => (
    <CardContent className="!p-20">
        <Stack direction={'row'} gap={2}>
            <CardHeader
                className="p-0"
                sx={{
                    "& .MuiCardHeader-avatar": { mr: "12px", ml: '0px' },
                    // "& .MuiAvatar-root": { border: '1px solid #FBB633' },
                }}
                avatar={<Skeleton animation="wave" variant="circular" width={140} height={140} />}

            />
            <Stack width={'100%'} className='mt-16'>
                <Skeleton sx={{ height: 28, width: '50%' }} animation="wave" variant="text" />
                <Skeleton sx={{ height: 16, width: '30%' }} animation="wave" variant="text" />
                <Stack direction={'row'} gap={2} className=" w-full mt-8" justifyContent={'flex-start'}>
                    <Skeleton sx={{ height: 65, width: '25%' }} animation="wave" variant="rectangular" className=' rounded-4' />
                    <Skeleton sx={{ height: 65, width: '25%' }} animation="wave" variant="rectangular" className=' rounded-4' />
                    <Skeleton sx={{ height: 65, width: '25%' }} animation="wave" variant="rectangular" className=' rounded-4' />
                </Stack>
            </Stack>
        </Stack>
        <CardContent className="!p-0  px-16">
            <Box className="mt-8" />
            <Skeleton sx={{ height: 28, width: '70px' }} animation="wave" variant="text" />
            <Skeleton sx={{ height: 16, }} animation="wave" variant="text" />
            <Skeleton sx={{ height: 16, }} animation="wave" variant="text" />
            <Divider className=" mb-16 mt-20 -mx-20" />
            <Stack direction={'row'} gap={2} className=" w-full mt-8" justifyContent={'flex-start'}>
                <Skeleton sx={{ height: 16, width: '70px' }} animation="wave" variant="text" />
                <Skeleton sx={{ height: 16, width: '70px' }} animation="wave" variant="text" />
                <Skeleton sx={{ height: 16, width: '70px' }} animation="wave" variant="text" />
            </Stack>

        </CardContent>
    </CardContent>
);

export default AgentSkeletonLoader;
