const mutation = {
    saveCommunity: `
        mutation saveCommunity($data: JSONString!) {
            saveCommunity (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    deleteBlog: `
        mutation deleteBlog($id: String!) {
            deleteBlog (id: $id) {
                status,
                res,
                message            
            }
        }
    `,
    blogToggleSave: `
        mutation blogToggleSave($data: JSONString!) {
            blogToggleSave (data: $data) {
                status,
                res,
                message
            }
        }
    `,
};

const query = {
    getCommunityList: `
        query getCommunityList($id: String) {
            getCommunityList(id: $id) {
                id
                title
                content
                userId
                dateCreated
                dateUpdated
                commentsCount
                views
                timeAgo
                agent{
                    id
                    username
                    name
                    firstName
                    lastName
                    email
                    dateCreated
                    dateUpdated
                }
            }
        }
    `,
    getCommunityId: `
        query getCommunityId($id: String) {
            getCommunityId(id: $id) {
                id
                title
                content
                userId
                dateCreated
                dateUpdated
                commentsCount
                views
                timeAgo
                agent{
                    id
                    username
                    name
                    firstName
                    lastName
                    email
                    dateCreated
                    dateUpdated
                }
            }
        }
    `,
    checkBlogTitle: `
        query checkBlogTitle($value: String, $id: String) {
            checkBlogTitle(value: $value, id: $id) {
                id
                title
            }
        }
    `,
    getBlogAuthorList: `
        query getBlogAuthorList($keyword: String) {
            getBlogAuthorList(keyword: $keyword) {
                id
                name
            }
        }
    `,
    getBlogTagList: `
        query getBlogTagList {
            getBlogTagList {
                id
                name
                dateCreated
                dateUpdated
            }
        }
    `,
    getBlogCategoryList: `
        query getBlogCategoryList {
            getBlogCategoryList {
                id
                name
                dateCreated
                dateUpdated
            }
        }
    `,
};
export { mutation, query };