import * as React from "react";
import Typography from "@mui/material/Typography";
import { Badge, Card, CardHeader, Stack, Box } from "@mui/material";
import CardContent from '@mui/material/CardContent';
import CardActions from "@mui/material/CardActions";
import format from "date-fns/format";
import AttachmentSvs from "app/services/AttachmentSvs";
import PhotoGridView from "app/services/PhotoGridView";
import SharerSvs from "app/services/SharerSvs";
import { PinIcon, SeenIcon, PinnedIcon, LikesIcon } from "../../../../custom-icon/MissionCounterIcons"
import SkeletonLoader from "app/main/apps/public/friends-activity/components/SkeletonLoader";
import { useEffect, useState } from "react";


import { Avatar, Button, Icon } from '@mui/material';
import { Link } from "react-router-dom";
import { agentById } from "../../store/agentSlice";
function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }

    return color;
}
function stringAvatar(name) {
    var username = name.split(", "),
        firstName = username[0].split(" ")[0][0],
        lastName = username[username.length - 1].split(" ")[0][0];
    if (firstName == lastName) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },

            children: `${firstName}`,
        };
    } else {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },

            children: `${lastName}${firstName}`,
        };
    }
}


function MapsContent({ missions }) {

    console.log("MapsContent", missions)

    const [missionLoading, setmissionLoading] = useState(true);

    useEffect(() => {
        setmissionLoading(false);
    }, [missions]);

    const renderSkeletonLoaders = (count) => {
        const skeletonLoaders = [];
        for (let i = 0; i < count; i++) {
            skeletonLoaders.push(
                <Stack key={i} direction={'column'} gap={1.5} className=" w-full">
                    <SkeletonLoader></SkeletonLoader>
                </Stack>
            );
        }
        return skeletonLoaders;
    };



    return (
        <>
            {missionLoading ? (
                renderSkeletonLoaders(3)
            ) : (
                <Card className="p-16 rounded-lg mb-18 mt-24 shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0}>
                    <Stack gap={3}>
                        {missions.length !== 0 ? (
                            missions.map((item, index) => (
                                <React.Fragment key={item.id}>



                                    <Stack direction={'row'} gap={3}>
                                        <div className=" w-216 h-136 ">
                                            {/* <img
                                                                                src={'assets/images/pi-images/no-image.png'}
                                                                                alt={''}
                                                                                loading="lazy"
                                                                                objectFit='cover'
                                                                                className=" h-full"
                                                                            /> */}
                                            {/* <AttachmentSvs module={"at-1"} path={item.agent?.profilePicPath} data={item.agent} /> */}
                                            <Link style={{ textDecoration: 'none' }} to={`/public/mission-details/${item.missionId}`}>
                                                <PhotoGridView fileId={item.filesId} single={true} isDisabledModal={true} />
                                            </Link>
                                        </div>
                                        <Stack className="flex-1 pr-12">
                                            <CardHeader
                                                className="p-0"
                                                sx={{
                                                    "& .MuiCardHeader-avatar": { mr: "12px", ml: '0px' },
                                                    "& .MuiAvatar-root": { border: '1px solid #FBB633' },
                                                }}
                                                avatar={
                                                    <Badge
                                                        overlap="circular"
                                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                        sx={{
                                                            '& .MuiBadge-anchorOriginBottomRightCircular': {



                                                            }
                                                        }}
                                                        badgeContent={
                                                            <span>
                                                                <PinnedIcon style={{ fill: '#FBB633' }} size={'small'} />
                                                            </span>
                                                        }
                                                    >
                                                        {item.agent?.profileUrl ? (
                                                            <Avatar
                                                                alt="user photo"
                                                                src={item.agent?.profileUrl}
                                                                className="bg-pi-light-grey"
                                                                style={{ height: '46px', width: '46px', textTransform: 'capitalize' }}
                                                            />
                                                        ) : (
                                                            <Avatar
                                                                alt={item.agent?.name}
                                                                {...stringAvatar(item.agent?.name)}
                                                                className="uppercase text-16"
                                                                style={{ height: '46px', width: '46px', textTransform: 'capitalize' }}
                                                            />
                                                        )}
                                                    </Badge>
                                                }
                                                title={
                                                    <Link style={{ textDecoration: 'none' }} to={`/public/mission-details/${item.missionId}`}>
                                                        <Typography className="font-extrabold text-18 flex gap-6">
                                                            {item.title}
                                                        </Typography>
                                                    </Link>
                                                }
                                                subheader={
                                                    <Typography className="line-clamp-1 text-16 text-pi-grey">
                                                        {'Created -' + item.timeAgo}
                                                    </Typography>
                                                }
                                            />
                                            <CardContent style={{ cursor: 'pointer' }} className="p-0 !pb-0">
                                                <Typography
                                                    className="text-16  transition duration-300 line-clamp-2 pt-4 !pb-0"
                                                >
                                                    <Link style={{ textDecoration: 'none' }} to={`/public/mission-details/${item.missionId}`}>
                                                        {item.description}
                                                    </Link>
                                                </Typography>
                                            </CardContent>

                                            <Stack flex={1} alignItems={'flex-end'} direction="row" gap={'18px'} justifyContent={'start'} className="w-full mt-6">
                                                <Typography className=" gap-1 flex items-center  text-pi-grey">
                                                    <div className="-mt-2">
                                                        <SeenIcon />
                                                    </div>
                                                    <span className="mt-1 text-16">{item.missionViews ? item.missionViews : '0'} views</span>
                                                </Typography>
                                                <Typography className=" gap-1 flex items-center text-pi-grey">
                                                    <LikesIcon />
                                                    <span className="mt-1 text-16">{item.missionCommentsCount ? item.missionCommentsCount : '0'} likes</span>
                                                </Typography>
                                                <Typography className=" gap-1 flex items-center text-pi-grey">
                                                    <div className="-mt-2"><PinIcon /> </div>
                                                    <span className="mt-1 text-16">   {item.missionPinned ? item.missionPinned : '0'} pinned</span>
                                                </Typography>

                                                <div className=" flex-1 text-right">
                                                    <SharerSvs link={`/public/mission-details/${item.missionId}`} title={item.title} description={item.description} />
                                                    {/* <Button variant="text" className="text-16 text-pi-grey !p-0 rounded-2"><Icon className="mr-6 text-18">share</Icon><span className="sm:flex hidden">share</span></Button> */}
                                                </div>
                                            </Stack>
                                        </Stack>

                                    </Stack>
                                </React.Fragment>
                            ))
                        ) : (
                            <CardContent className="!p-0">
                                <Typography
                                    component="div"
                                    className="font-bold mt-5 text-18 mb-0 text-pi-black line-clamp-2"
                                >
                                    There are no created missions available
                                </Typography>
                            </CardContent>
                        )}
                    </Stack>
                </Card>
            )}
        </>
    );
}

export default MapsContent;

