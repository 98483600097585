import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import CONFIG from 'app/config';
import { query, mutation } from "../graphql/community_graphql";
import { showMessage } from "app/store/fuse/messageSlice";
import uploadSvc from "app/services/upload";
import randomString from "app/services/randomString";
import { mutation as mutationUpload } from "app/graphql/upload_graphql";
import format from 'date-fns/format';
import JwtService from "app/services/jwtService";
import { isEmpty } from "lodash";

export const saveCommunity = createAsyncThunk(
    "communityApp/saveCommmunity",
    async (params, { dispatch, getState }) => {

        return new Promise(async (resolve, reject) => {

            

            saveImages(params).then(async (params) => {

                console.log("params", params)
                
                const response = await axios.post(CONFIG.API + "/api/", {
                    query: mutation.saveCommunity,
                    variables: { data: JSON.stringify(params) },
                    publicUrl: 'agents'
                });

                if (response.data.data.saveCommunity) {
                    dispatch(
                        showMessage({
                            message: "Community has been successfully saved.",
                            autoHideDuration: 5000, //ms
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right",
                            },
                            variant: "success",
                        })
                    );
                    resolve(true);
                } else {
                    dispatch(
                        showMessage({
                            message: "Something went wrong. Please try again later.",
                            autoHideDuration: 5000, //ms
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right",
                            },
                            variant: "error",
                        })
                    );
                    resolve(true);
                }
            });
        })
    }
);



export const getCommunityList = createAsyncThunk(
    "communityApp/getCommunityList",
    async (params) => {
        console.log("params", params)
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getCommunityList,
            variables: { id: params }
        });

        console.log("response",response)

        if (response.data.data.getCommunityList) {
            const data = response.data.data.getCommunityList;

            return data === undefined ? null : data;
        } else {
            return null;
        }
    }
);

export const getCommunityId = createAsyncThunk(
    "communityApp/getCommunityId",
    async (params) => {
        console.log("params", params)
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getCommunityId,
            variables: { id: params }
        });

        console.log("response", response)

        if (response.data.data.getCommunityId) {
            const data = response.data.data.getCommunityId;

            return data === undefined ? null : data;
        } else {
            return null;
        }
    }
);


const uploadImages = (uploadData, itemData, image) => {

    return new Promise(async (resolve, reject) => {

        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutationUpload.create,
            variables: {
                data: JSON.stringify(uploadData),
            },
        });

        if (response.data.data.createUpload.status == "success") {
            var upload = response.data.data.createUpload.upload;

            if (itemData.featuredImageId == image.image.id) {
                itemData.featuredImagePath = upload.path;
            }

            itemData.images[image.index].upload_id = upload.id;
            itemData.images[image.index].file_name = upload.fileName;
            itemData.images[image.index].file_id = upload.fileId;
            itemData.images[image.index].file_type = upload.fileType;
            itemData.images[image.index].path = upload.path;
            itemData.images[image.index].module = upload.module;
            itemData.images[image.index].url = "";

            resolve(itemData);
        }

    });

}

const saveImages = (params) => {

    return new Promise(async (resolve, reject) => {

        console.log("saveImages", params)

        if (params.images.length) {

            var newImages = [];

            params.images.map(async (image, index) => {

                if (image.status === "new") {
                    newImages.push({
                        index: index,
                        image: image
                    })
                } else {
                    if (params.featuredImageId == image.fileId) {
                        params.featuredImagePath = image.path;
                    }
                }

            });

            if (newImages.length) {

                var tempCount = 0;

                newImages.map(async (image, index) => {

                    await uploadSvc({
                        file: image.image.file,
                        destination_file_name: "Blogs/images/blogs-" + randomString() + "-" + (image.image.file ? image.image.file.name : image.image.fileName)
                    }).then(async (result) => {

                        if (result.status === 200 && result.data.status === "success") {

                            var uploadData = result.data.uploadData
                            uploadData['module'] = 'blogs-images';
                            uploadData['file_id'] = image.image.id;

                            await uploadImages(uploadData, params, image).then(async (params) => {

                                if (params) {
                                    tempCount++;
                                    if (newImages.length == tempCount) {
                                        resolve(params);
                                    }
                                }

                            });
                        }

                    });

                });

            } else {
                resolve(params)
            }


        } else {

            resolve(params);

        }

    });

}





const blogManageSlice = createSlice({
    name: 'communitysApp',
    initialState: null,
    reducers: null,
    extraReducers: {
        [saveCommunity.fulfilled]: (state, action) => action.payload,
        [getCommunityList.fulfilled]: (state, action) => action.payload,
    },
});

export default blogManageSlice.reducer;
